import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly &&
      (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
        return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
      })),
      keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2
      ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        })
      : _Object$getOwnPropertyDescriptors
      ? _Object$defineProperties(
          target,
          _Object$getOwnPropertyDescriptors(source)
        )
      : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(
            target,
            key,
            _Object$getOwnPropertyDescriptor(source, key)
          );
        });
  }
  return target;
}
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useEffect } from "react";
import isString from "lodash/isString";
import uniq from "lodash/uniq";
import {
  EVENT_ADDED,
  EVENT_EXPIRED,
  EVENT_RENEWED,
  EVENT_ERROR,
  EVENT_REMOVED,
} from "@okta/okta-auth-js";
import { error } from "@jutro/logger";
import { getConfigValue } from "@jutro/config";
import { joinUrl } from "@jutro/platform";
import { getTokenByKey, setToken, removeTokenByKey } from "./AuthTokenStorage";
import { OKTA_NOT_FOUND } from "./errorcodes/errorcodes";
import { useAuthState } from "./AuthStateProvider";
import { AuthContext } from "./AuthContext";
import { getTokenValue } from "./getTokenValue";
export var AuthContextProvider = function (_ref) {
  var children = _ref.children,
    _useAuthState = useAuthState(),
    authState = _useAuthState.authState,
    oktaAuth = _useAuthState.oktaAuth,
    tokenManager = oktaAuth.tokenManager,
    basenameUri = getConfigValue("JUTRO_ROUTER_BASENAME", "");

    const getAppName = () => {
      const logoutRedirectPath = String(getConfigValue("JUTRO_AUTH_LOGOUT_REDIRECT_PATH",""))
      if (logoutRedirectPath.includes("producer-engage")) {
          return "PE"
      } else if (logoutRedirectPath.includes("customer-engage") || logoutRedirectPath.includes("account-management")) {
          return "CE"
      }
      return ""
  }
  const getAppDomain = () => {
      // Extract hostname from window.location
      const { hostname } = window.location
      if(hostname == "localhost")
          return hostname
      // Parse hostname to return domain
      const index = hostname.indexOf('.')
      return (index > 0 ? hostname.slice(index+1) : hostname)
  }

  useEffect(
    function () {
      document.cookie = `appNameCookie=${getAppName()}; domain=${getAppDomain()}; path=/`;
      var autoRenew = getConfigValue("JUTRO_AUTH_AUTO_RENEW", !0),
        expiredHandler = function () {
          if (!autoRenew) {
            var logoutUri = getConfigValue(
                "JUTRO_AUTH_LOGOUT_REDIRECT_PATH",
                "/logout"
              ),
              postLogoutRedirectUri = joinUrl(
                logoutUri
              );
            logout().catch(function () {
              tokenManager.clear(),
                window.location.assign(postLogoutRedirectUri);
            });
          }
        },
        addHandler = function (key, token) {
          var tokenValue = getTokenValue(token);
          setToken(key, tokenValue);
        },
        errorHandler = function () {
          error("Jutro Auth: failed to renew the token. Logging out..."),
            logout();
        },
        removeHandler = function (key) {
          removeTokenByKey(key);
        };
      return (
        tokenManager.on(EVENT_ADDED, addHandler),
        tokenManager.on(EVENT_RENEWED, addHandler),
        tokenManager.on(EVENT_REMOVED, removeHandler),
        tokenManager.on(EVENT_EXPIRED, expiredHandler),
        tokenManager.on(EVENT_ERROR, errorHandler),
        tokenManager.getTokens().then(function (_ref2) {
          var idToken = _ref2.idToken,
            accessToken = _ref2.accessToken;
          idToken &&
            !tokenManager.hasExpired(idToken) &&
            setToken("idToken", idToken.idToken),
            accessToken &&
              !tokenManager.hasExpired(accessToken) &&
              setToken("accessToken", accessToken.accessToken);
        }),
        function () {
          tokenManager.off(EVENT_ADDED, addHandler),
            tokenManager.off(EVENT_RENEWED, addHandler),
            tokenManager.off(EVENT_REMOVED, removeHandler),
            tokenManager.off(EVENT_EXPIRED, expiredHandler),
            tokenManager.off(EVENT_ERROR, errorHandler);
        }
      );
    },
    [tokenManager]
  );
  var _ref3,
    _ref4,
    _ref5,
    _ref6,
    getDefaultScopes = function () {
      return oktaAuth.options.scopes || [];
    },
    getTransientTokens =
      ((_ref3 = _asyncToGenerator(
        _regeneratorRuntime.mark(function _callee(scopes) {
          var allocateMethod,
            token,
            newTokensResponse,
            _args = arguments;
          return _regeneratorRuntime.wrap(function (_context) {
            for (;;)
              switch ((_context.prev = _context.next)) {
                case 0:
                  return (
                    (allocateMethod =
                      _args.length > 1 && void 0 !== _args[1]
                        ? _args[1]
                        : "getWithoutPrompt"),
                    (token = oktaAuth.token),
                    (_context.next = 4),
                    token[allocateMethod]({ scopes: scopes })
                  );
                case 4:
                  return (
                    (newTokensResponse = _context.sent),
                    _context.abrupt("return", newTokensResponse.tokens)
                  );
                case 6:
                case "end":
                  return _context.stop();
              }
          }, _callee);
        })
      )),
      function (_x) {
        return _ref3.apply(this, arguments);
      }),
    allocateToken =
      ((_ref4 = _asyncToGenerator(
        _regeneratorRuntime.mark(function _callee2() {
          var _context2,
            scopes,
            newTokenKey,
            allocateMethod,
            tokenScopes,
            tokens,
            accessToken,
            _args2 = arguments;
          return _regeneratorRuntime.wrap(function (_context3) {
            for (;;)
              switch ((_context3.prev = _context3.next)) {
                case 0:
                  if (
                    ((scopes =
                      _args2.length > 0 && void 0 !== _args2[0]
                        ? _args2[0]
                        : []),
                    (newTokenKey = _args2.length > 1 ? _args2[1] : void 0),
                    (allocateMethod =
                      _args2.length > 2 && void 0 !== _args2[2]
                        ? _args2[2]
                        : "getWithoutPrompt"),
                    scopes && 0 !== scopes.length)
                  ) {
                    _context3.next = 7;
                    break;
                  }
                  return (
                    error("scopes are not provided"),
                    _context3.abrupt("return", void 0)
                  );
                case 7:
                  if (newTokenKey) {
                    _context3.next = 11;
                    break;
                  }
                  return (
                    error("newTokenKey is not provided"),
                    _context3.abrupt("return", void 0)
                  );
                case 11:
                  return (
                    (tokenScopes = uniq(
                      _concatInstanceProperty(
                        (_context2 = getDefaultScopes())
                      ).call(_context2, scopes)
                    )),
                    (_context3.next = 14),
                    getTransientTokens(tokenScopes, allocateMethod)
                  );
                case 14:
                  return (
                    (tokens = _context3.sent),
                    (accessToken = tokens.accessToken) &&
                      (setToken(newTokenKey, accessToken.accessToken),
                      tokenManager.add(newTokenKey, accessToken)),
                    _context3.abrupt("return", getTokenByKey(newTokenKey))
                  );
                case 18:
                case "end":
                  return _context3.stop();
              }
          }, _callee2);
        })
      )),
      function () {
        return _ref4.apply(this, arguments);
      }),
    login =
      ((_ref5 = _asyncToGenerator(
        _regeneratorRuntime.mark(function _callee3(fromUri) {
          var additionalParams,
            loginRedirectFallBack,
            loginUri,
            idp,
            params,
            _args3 = arguments;
          return _regeneratorRuntime.wrap(function (_context4) {
            for (;;)
              switch ((_context4.prev = _context4.next)) {
                case 0:
                  return (
                    (additionalParams =
                      _args3.length > 1 && void 0 !== _args3[1]
                        ? _args3[1]
                        : {}),
                    (loginRedirectFallBack = window.location.href.replace(
                      joinUrl(window.location.origin, basenameUri),
                      ""
                    )),
                    (loginUri = isString(fromUri)
                      ? fromUri
                      : loginRedirectFallBack),
                    _includesInstanceProperty(loginUri).call(
                      loginUri,
                      "/logout"
                    ) && (loginUri = "/"),
                    (idp = getConfigValue("JUTRO_AUTH_IDP")),
                    (params = _objectSpread(
                      _objectSpread({}, idp && { idp: idp }),
                      additionalParams
                    )),
                    oktaAuth.setOriginalUri(
                      joinUrl(window.location.origin, basenameUri, loginUri, {
                        trailingSlash: !0,
                      })
                    ),
                    (_context4.next = 9),
                    oktaAuth.signInWithRedirect(params)
                  );
                case 9:
                case "end":
                  return _context4.stop();
              }
          }, _callee3);
        })
      )),
      function (_x2) {
        return _ref5.apply(this, arguments);
      }),
    logout =
      ((_ref6 = _asyncToGenerator(
        _regeneratorRuntime.mark(function _callee4(fromUri) {
          var logoutUri, postLogoutRedirectUri;
          return _regeneratorRuntime.wrap(function (_context5) {
            for (;;)
              switch ((_context5.prev = _context5.next)) {
                case 0:
                  return (
                    (logoutUri = isString(fromUri)
                      ? fromUri
                      : getConfigValue(
                          "JUTRO_AUTH_LOGOUT_REDIRECT_PATH",
                          "/logout"
                        )),
                    (postLogoutRedirectUri = joinUrl(
                      logoutUri
                    )),
                    _context5.abrupt(
                      "return",
                      oktaAuth
                        .signOut({
                          postLogoutRedirectUri: postLogoutRedirectUri,
                        })
                        .catch(function (err) {
                          var errorCode = err.errorCode,
                            errorMsg = "Jutro Auth logout failure: ".concat(
                              errorCode
                            );
                          if ((error(errorMsg), errorCode !== OKTA_NOT_FOUND))
                            throw new Error(errorMsg);
                        })
                    )
                  );
                case 3:
                case "end":
                  return _context5.stop();
              }
          }, _callee4);
        })
      )),
      function (_x3) {
        return _ref6.apply(this, arguments);
      }),
    getAccessToken = function () {
      return oktaAuth.getAccessToken();
    },
    getIdToken = function () {
      return oktaAuth.getIdToken();
    },
    decodeToken = function (token) {
      return oktaAuth.token.decode(token);
    },
    getDecodedIdToken = function () {
      var token = getIdToken();
      if (token) return decodeToken(token);
    },
    getDecodedAccessToken = function () {
      var token = getAccessToken();
      if (token) return decodeToken(token);
    };
  return React.createElement(
    AuthContext.Provider,
    {
      value: _objectSpread(
        _objectSpread({}, authState),
        {},
        {
          authenticated: authState.isAuthenticated,
          tokenManager: tokenManager,
          getDefaultScopes: getDefaultScopes,
          getTransientTokens: getTransientTokens,
          allocateToken: allocateToken,
          decodeToken: decodeToken,
          getAccessToken: getAccessToken,
          getDecodedIdToken: getDecodedIdToken,
          getDecodedAccessToken: getDecodedAccessToken,
          getIdToken: getIdToken,
          login: login,
          logout: logout,
        }
      ),
    },
    children
  );
};
(AuthContextProvider.displayName = "AuthContextProvider"),
  (AuthContextProvider.__docgenInfo = {
    description: "",
    methods: [],
    displayName: "AuthContextProvider",
  }),
  (AuthContextProvider.__docgenInfo = {
    componentName: "AuthContextProvider",
    packageName: "@jutro/auth",
    description: "",
    displayName: "AuthContextProvider",
    methods: [],
    actualName: "AuthContextProvider",
  });
