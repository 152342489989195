import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import isBoolean from "lodash/isBoolean";
import isFunction from "lodash/isFunction";
import {
	ModalNextEmitter
} from "@jutro/components";
import {
	log
} from "@jutro/logger";
import {
	PromptService
} from "./PromptService_DEPRECATED";
import {
	handleAction
} from "./actionHelper";
export var routeConfirmationModal = function(
  promptName, 
  promptCallback, 
  modalEmitter = ModalNextEmitter
) {
	if (promptName) {
		var promptProps;
		try {
			promptProps = JSON.parse(promptName)
		} catch (e) {
			var _PromptService$popPro;
			promptProps = null !== (_PromptService$popPro = PromptService.popPrompt(promptName)) && void 0 !== _PromptService$popPro ? _PromptService$popPro : {
				title: "Are you sure?",
				message: promptName
			}
		}
		var _promptProps = promptProps,
			title = _promptProps.title,
			message = _promptProps.message,
			showConfirm = modalEmitter.showConfirm;
		if (title && message) {
			showConfirm({
				title: title,
				message: message,
				status: "warning",
				icon: "gw-warning"
			}).then((function(action) {
				"cancel" === action ? promptCallback(!1) : handleTriggerEvent(promptProps, promptCallback, modalEmitter)
			}), (function() {
				promptCallback(!1)
			}))
		} else handleTriggerEvent(promptProps, promptCallback, modalEmitter)
	} else promptCallback(!0)
};

function handleTriggerEvent(_x, _x2, _x3) {
	return _handleTriggerEvent.apply(this, arguments)
}

function _handleTriggerEvent() {
	return (_handleTriggerEvent = _asyncToGenerator(_regeneratorRuntime.mark((function _callee(promptProps, promptCallback, modalEmitter) {
		var trigger, instance, triggerAnswer;
		return _regeneratorRuntime.wrap((function(_context) {
			for (;;) switch (_context.prev = _context.next) {
				case 0:
					if (trigger = promptProps.trigger, instance = promptProps.instance, !trigger || !isFunction(trigger)) {
						_context.next = 19;
						break
					}
					if (!((triggerAnswer = trigger()) instanceof Object)) {
						_context.next = 16;
						break
					}
					return _context.prev = 4, _context.next = 7, handleAction(triggerAnswer, instance, null, modalEmitter);
				case 7:
					promptCallback(instance.props.history.location === instance.props.location), _context.next = 14;
					break;
				case 10:
					_context.prev = 10, _context.t0 = _context.catch(4), log.error(_context.t0), promptCallback(!1);
				case 14:
					_context.next = 17;
					break;
				case 16:
					isBoolean(triggerAnswer) ? promptCallback(triggerAnswer) : promptCallback(!0);
				case 17:
					_context.next = 20;
					break;
				case 19:
					promptCallback(!0);
				case 20:
				case "end":
					return _context.stop()
			}
		}), _callee, null, [
			[4, 10]
		])
	})))).apply(this, arguments)
}